import React, { useState, useEffect } from "react"

export default function LoadingComponent() {
  const [dots, setDots] = useState(0)
  useEffect(() => {
    let dotsInterval = setInterval(() => {
      if (dots <= 5) {
        setDots(dots => dots + 1)
      }
    }, 1000)

    return () => {
      clearInterval(dotsInterval)
    }
  }, [])
  return <h1>Loading{Array(dots).fill(".")}</h1>
}
