import React from "react"
import Experience, { ExperienceV2 } from "./Experience"

export default function GiftAnimation(props) {
  return (
    <section className="gift-animation">
      {props.giftDetails && <Experience {...props} />}
    </section>
  )
}

export function GiftAnimationV2(props) {
  return (
    <section className="gift-animation">
      {props.giftDetails && <ExperienceV2 {...props} />}
    </section>
  )
}
