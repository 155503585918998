import React, { useState, useEffect } from "react"
import styled from "styled-components"
import VideoIcon from "../assets/images/video_icon.png"
import WrappedGift from "../assets/images/wrapped_gift.png"

function openGreetingCard(props) {
  const front = document.querySelector(".greeting-card-front")
  const back = document.querySelector(".greeting-card-back")
  const backStill = document.querySelector(".greeting-card-back-still")
  const backStillRight = document.querySelector(".back-still-right")
  const gcContainer = document.querySelector(".greeting-card-container")
  if (front && back && backStill) {
    front.classList.add("greeting-card-front-hover")
    front.classList.remove("greeting-card-front")
    back.classList.add("greeting-card-back-hover")
    back.classList.remove("greeting-card-back")
    backStill.classList.add("greeting-card-back-still-hover")
    backStill.classList.remove("greeting-card-back-still")
    if (window.innerWidth <= 700) {
      gcContainer.style.marginLeft = "15px"
    }
    setTimeout(() => {
      backStillRight.style.zIndex = 1
      setTimeout(() => {
        props.setShowGift(true)
        props.setGiftOpen(true)
      }, 3000)
    }, 1000)
  }
}

function openGreetingCardV2(props, setShowVideo) {
  const front = document.querySelector(".greeting-card-front")
  const back = document.querySelector(".greeting-card-back")
  const backStill = document.querySelector(".greeting-card-back-still")
  const backStillRight = document.querySelector(".back-still-right")
  const gcContainer = document.querySelector(".greeting-card-container")
  if (front && back && backStill) {
    front.classList.add("greeting-card-front-hover")
    front.classList.remove("greeting-card-front")
    back.classList.add("greeting-card-back-hover")
    back.classList.remove("greeting-card-back")
    // backStill.classList.add("greeting-card-back-still-hover")
    // backStill.classList.remove("greeting-card-back-still")
    if (window.innerWidth <= 700) {
      gcContainer.style.marginLeft = "15px"
    }
    setTimeout(() => {
      backStillRight.style.zIndex = 100
      if (
        props.giftDetails &&
        props.giftDetails.experience &&
        props.giftDetails.experience.greeting_card &&
        props.giftDetails.experience.greeting_card.uploaded_video
      ) {
        setShowVideo(true)
      }
    }, 1000)
  }
}

const GreetingFrontDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  background: none;
  width: 100%;
  height: 100%;
  transition: all 1s ease-in;
`
const GreetingFrontLeft = styled.div`
  flex: 1;
`
const GreetingFrontRight = styled.div`
  flex: 1;
`
const GreetingFrontImage = styled.img`
  box-shadow: 0px 0px 5px 2px lavender, 0px 0px 0px 2px grey;
  max-width: 100%;
  /* max-height: 100%; */
`
const GreetingFrontDivV2 = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  background: none;
  width: 100%;
  height: 100%;
  transition: all 1s ease-in;
`
const GreetingFrontLeftV2 = styled.div`
  flex: 1;
`
const GreetingFrontRightV2 = styled.div`
  flex: 1;
`
const GreetingFrontImageV2 = styled.div`
  background: url(${props => props.fluidimage}) no-repeat center center, white;
  background-size: cover;
  box-shadow: 0px 0px 5px 2px lavender, 0px 0px 0px 2px grey;
  max-width: 100%;
  /* max-height: 100%; */
`

function GreetingCardFront({ fluidimage, greetingcard }) {
  return (
    <GreetingFrontDiv className="greeting-card-front">
      <GreetingFrontLeft className="front-left" />
      <GreetingFrontRight className="front-right">
        <GreetingFrontImage
          className="front-image"
          src={fluidimage}
          alt={greetingcard.product_name}
        />
      </GreetingFrontRight>
    </GreetingFrontDiv>
  )
}

function GreetingCardFrontV2(props) {
  return (
    <GreetingFrontDivV2 className="greeting-card-front">
      <GreetingFrontLeftV2 className="front-left" />
      <GreetingFrontRightV2 className="front-right">
        <GreetingFrontImageV2 className="front-image" {...props} />
      </GreetingFrontRightV2>
    </GreetingFrontDivV2>
  )
}

const GreetingBackDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  flex: 1;
  background: none;
  width: 100%;
  height: 100%;
  transition: all 1s ease-in;
`
const GreetingBackLeft = styled.div`
  flex: 1;
`
const GreetingBackRight = styled.div`
  flex: 1;
  background: url(${props => props.fluidimage}) no-repeat center center, white;
  background-size: cover;
  transform: rotateY(-180deg);
  box-shadow: 0 5px 10px 2px grey;
`
const GreetingBackRightGradient = styled.div`
  height: 100%;
  background: linear-gradient(to right, transparent, #cccccc10, #00000020);
`

function GreetingCardBack(props) {
  return (
    <GreetingBackDiv className="greeting-card-back">
      <GreetingBackLeft className="back-left" {...props} />
      <GreetingBackRight className="back-right" {...props}>
        <GreetingBackRightGradient />
      </GreetingBackRight>
    </GreetingBackDiv>
  )
}

const GreetingBackDivV2 = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  flex: 1;
  background: none;
  width: 100%;
  height: 100%;
  transition: all 1s ease-in;
`
const GreetingBackLeftV2 = styled.div`
  flex: 1;
`
const GreetingBackRightV2 = styled.div`
  position: relative;
  flex: 1;
  background: url(${props => props.fluidimage}) no-repeat center center, white;
  background-size: cover;
  transform: rotateY(-180deg);
  box-shadow: 0 5px 10px 2px grey;
  .video-icon__wrapper {
    position: absolute;
    top: calc(100% - 75px);
    left: 1rem;
    width: 75px;
    height: 50px;
    font-size: 0.75rem;
    font-family: "OpenSans-Bold";
    text-transform: uppercase;
    text-align: center;
    img {
      max-height: 100%;
      max-width: 100%;
      cursor: pointer;
    }
    &:hover {
      border: 1px solid white;
      border-radius: 10px;
    }
  }
`
const GreetingBackRightGradientV2 = styled.div`
  height: 100%;
  background: linear-gradient(to right, transparent, #cccccc10, #00000020);
`

function GreetingCardBackV2(props) {
  return (
    <GreetingBackDivV2 className="greeting-card-back">
      <GreetingBackLeftV2 className="back-left" {...props} />
      <GreetingBackRightV2 className="back-right" {...props}>
        <GreetingBackRightGradientV2 />
        {props.uploadedVideo && (
          <div className="video-icon__wrapper">
            <img
              src={VideoIcon}
              alt="video"
              onClick={() => props.setShowVideo(true)}
            />
            play video
          </div>
        )}
      </GreetingBackRightV2>
    </GreetingBackDivV2>
  )
}

const GreetingBackStillDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  flex: 1;
  background: none;
  width: 100%;
  height: 100%;
  transition: all 1s ease-in;
`
const GreetingBackStillLeft = styled.div`
  flex: 1;
`
const GreetingBackStillRight = styled.div`
  flex: 1;
`
const GreetingBackStillRightShadow = styled.div`
  background: #fff;
  box-shadow: 0px 0px 5px 2px lavender, 0px 0px 0px 2px grey;
  box-shadow: 0 5px 10px 2px grey;
  height: 100%;
`
const GreetingBackStillRightMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`
const GreetingBackStillRightMessageDiv = styled.div`
  flex: 1;
  padding: 1em;
  word-break: break-all;
  background: linear-gradient(to left, transparent, #cccccc10, #00000020);
  font-size: 1rem;
`

function GreetingCardBackStill({ children, greetingcard }) {
  return (
    <GreetingBackStillDiv className="greeting-card-back-still">
      <GreetingBackStillLeft className="back-still-left">
        <p>{greetingcard.product_description}</p>
      </GreetingBackStillLeft>
      <GreetingBackStillRight className="back-still-right">
        <GreetingBackStillRightShadow>
          <GreetingBackStillRightMessageWrapper>
            <GreetingBackStillRightMessageDiv>
              {children}
            </GreetingBackStillRightMessageDiv>
          </GreetingBackStillRightMessageWrapper>
        </GreetingBackStillRightShadow>
      </GreetingBackStillRight>
    </GreetingBackStillDiv>
  )
}

const GreetingBackStillDivV2 = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  background: none;
  width: 50%;
  height: 100%;
`
const GreetingBackStillRightV2 = styled.div`
  position: relative;
  justify-self: right;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 5px 2px lavender, 0px 0px 0px 2px grey;
  box-shadow: 0 5px 10px 2px grey;
  justify-content: center;
  background: #fff;
  background: linear-gradient(to left, transparent, #cccccc10, #00000020);
  .text-content {
    width: 100%;
    height: calc(100% - 100px);
    padding: 1rem;
    overflow-y: scroll;
    word-break: break-word;
    scrollbar-width: thin;
    scrollbar-color: #ececec #2d2d2d10;

    &::-webkit-scrollbar {
      width: 0.4rem;
      height: 0;
      right: 0.25rem;
    }
    &::-webkit-scrollbar-track {
      /* border: 1px solid var(--grey-20-color); */
      border: none;
      background-color: white;
      opacity: 0.5;
      border-radius: 3px;
      margin: 0.5rem;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #ececec;
      border-radius: 3px;
    }
  }
`

function GreetingCardBackStillV2(props) {
  return (
    <GreetingBackStillDivV2 className="greeting-card-back-still">
      <GreetingBackStillRightV2 className="back-still-right">
        <div className="text-content">{props.children}</div>
        {props.openStatus && <OpenGiftButtonWrapper {...props} />}
      </GreetingBackStillRightV2>
    </GreetingBackStillDivV2>
  )
}

const GreetingCardContainerWrapper = styled.div`
  display: grid;
  justify-items: start;
`

const GreetingCardContainer = styled.div`
  position: relative;
  perspective: 100vw;
  perspective-origin: center center;
  transition: all 1s ease-in;
  width: 47%;
  margin-left: 15%;
  @media (max-width: 899px) {
    height: 230px;
  }
  @media (max-width: 699px) {
    height: 250px;
    width: 93%;
    margin-left: -50px;
  }
  @media (min-width: 900px) {
    height: 300px;
  }
  @media (min-width: 1000px) {
    height: 320px;
  }
  @media (min-width: 1100px) {
    height: 350px;
  }
  @media (min-width: 1200px) {
    height: 350px;
  }
  @media (min-width: 1400px) {
    height: 350px;
  }
`

const GreetingCardContainerWrapperV2 = styled.div`
  position: relative;
  display: grid;
  justify-items: center;
  width: 100%;
  padding: 5rem 0;
`

const GreetingCardContainerV2 = styled.div`
  position: relative;
  perspective: 100vw;
  perspective-origin: center center;
  transition: all 1s ease-in;
  width: 90%;
  height: 650px;
  @media (max-width: 1200px) {
    width: 75%;
    height: 600px;
  }
  @media (max-width: 1100px) {
    width: 75%;
    height: 550px;
  }
  @media (max-width: 1000px) {
    width: 85%;
    height: 500px;
  }
  @media (max-width: 850px) {
    width: 85%;
    height: 450px;
  }
  @media (max-width: 768px) {
    width: 82%;
    height: 400px;
  }
  @media (max-width: 700px) {
    width: 80%;
    height: 350px;
  }
  @media (max-width: 650px) {
    width: 75%;
    height: 300px;
  }
  @media (max-width: 600px) {
    width: 85%;
    height: 330px;
  }
  @media (max-width: 550px) {
    width: 95%;
    height: 280px;
  }
  @media (max-width: 400px) {
    width: 95%;
    height: 250px;
  }
  @media (max-width: 375px) {
    width: 95%;
    height: 240px;
  }
`

export default function GreetingCardAnimated(props) {
  const [openStatus, setOpenStatus] = useState(false)
  const [unwrapClicked, setUnwrapClicked] = useState(false)

  useEffect(() => {
    const openTimer = setTimeout(() => {
      if (props.startExperience) {
        openGreetingCard(props)
        setOpenStatus(true)
        props.openclicked()
      }
    }, 1000)
    return () => {
      clearTimeout(openTimer)
    }
  }, [props])

  return (
    <GreetingCardContainerWrapper>
      <GreetingCardContainer className="greeting-card-container">
        <GreetingCardFront
          fluidimage={props.frontimage}
          greetingcard={props.greetingcard}
        />
        <GreetingCardBack
          fluidimage={props.backimage}
          defaultgreeting={`Greetings!!`}
        />
        <GreetingCardBackStill greetingcard={props.greetingcard}>
          {props.children}
        </GreetingCardBackStill>
      </GreetingCardContainer>
    </GreetingCardContainerWrapper>
  )
}

const OpenGiftBtn = styled.a`
  position: absolute;
  top: calc(100% - 75px);
  right: 1rem;
  /* z-index: 1; */
  background-color: none;
  outline: none;
  width: 75px;
  height: 75px;
  font-size: 0.75rem;
  font-family: "OpenSans-Bold";
  text-transform: uppercase;
  text-align: center;
  img {
    &:hover {
      border: 1px solid #14a0b320;
      border-radius: 10px;
    }
    width: 100%;
    margin-bottom: 0.15rem;
  }
`

function OpenGiftButtonWrapper(props) {
  return (
    <OpenGiftBtn
      onClick={e => {
        e.preventDefault()
        props.setShowGift(true)
        props.setGiftOpen(true)
        props.setStartMusic(true)
      }}
    >
      <img src={WrappedGift} alt="gift" />
      open gift
    </OpenGiftBtn>
  )
}

const VideoModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 100;
  .overlay {
    width: 100%;
    height: 100%;
    background-color: #2d2d2d50;
  }
  .video-container {
    position: fixed;
    top: calc(50% - 175px);
    left: calc(50% - 300px);
    width: 600px;
    height: 350px;
    @media (max-width: 768px) {
      top: calc(50% - 150px);
      left: calc(50% - 250px);
      width: 500px;
      height: 300px;
    }
    @media (max-width: 550px) {
      top: calc(50% - 100px);
      left: calc(50% - 175px);
      width: 350px;
      height: 200px;
    }
    @media (max-width: 350px) {
      top: calc(50% - 100px);
      left: calc(50% - 150px);
      width: 300px;
      height: 200px;
    }
    background-color: white;
    border-radius: 10px;
    text-align: right;
    .title-bar {
      height: 40px;
      background-image: repeating-linear-gradient(
        135deg,
        transparent,
        transparent 10px,
        #2d2d2d10 10px,
        #2d2d2d10 20px
      );
    }
    a {
      font-size: 2rem;
      font-family: "OpenSans-Regular";
      padding: 1rem 0.5rem;
    }
    video {
      width: 100%;
      height: 100%;
    }
  }
`

function VideoModal(props) {
  return (
    <VideoModalWrapper>
      <div
        className="overlay"
        onClick={e => {
          e.preventDefault()
          props.setShowVideo(false)
        }}
      ></div>
      <div className="video-container">
        <div className="title-bar">
          <a
            href="#"
            onClick={e => {
              e.preventDefault()
              props.setShowVideo(false)
            }}
          >
            &times;
          </a>
        </div>
        <video
          autoPlay
          controls
          muted
          poster={`${process.env.GATSBY_EGIFTIT_B2B}${
            props.giftDetails &&
            props.giftDetails.experience &&
            props.giftDetails.experience.greeting_card &&
            props.giftDetails.experience.greeting_card.poster_image
          }`}
          onEnded={e => {
            e.preventDefault()
            props.setShowVideo(false)
          }}
        >
          <source
            src={`${process.env.GATSBY_EGIFTIT_B2B}${
              props.giftDetails &&
              props.giftDetails.experience &&
              props.giftDetails.experience.greeting_card &&
              props.giftDetails.experience.greeting_card.uploaded_video
            }`}
            type="video/mp4"
          />
          Sorry, your browser doesn't support embedded videos.
        </video>
      </div>
    </VideoModalWrapper>
  )
}

export function GreetingCardAnimatedV2(props) {
  const [openStatus, setOpenStatus] = useState(false)
  const [unwrapClicked, setUnwrapClicked] = useState(false)
  const [showVideo, setShowVideo] = useState(false)
  const [startMusic, setStartMusic] = useState(false)

  useEffect(() => {
    const openTimer = setTimeout(() => {
      if (props.startExperience) {
        openGreetingCardV2(props, setShowVideo)
        setTimeout(() => {
          setOpenStatus(true)
        }, 500)
        props.openclicked()
      }
    }, 1000)
    return () => {
      clearTimeout(openTimer)
    }
  }, [props])

  useEffect(() => {
    if (showVideo) {
      pauseMusic()
    }
  }, [showVideo])

  useEffect(() => {
    if (startMusic) {
      playMusic()
    }
  }, [startMusic])

  function playMusic() {
    const tune = document.querySelector(`.selected-audio-tune`)
    if (tune) {
      tune.currentTime = 0
      tune.play()
    }
  }

  function pauseMusic() {
    const tune = document.querySelector(`.selected-audio-tune`)
    if (tune) {
      tune.pause()
    }
  }

  return (
    <>
      <GreetingCardContainerWrapperV2>
        <GreetingCardContainerV2 className="greeting-card-container">
          <GreetingCardFrontV2
            fluidimage={props.frontimage}
            greetingcard={props.greetingcard}
          />
          <GreetingCardBackV2
            uploadedVideo={
              props.giftDetails &&
              props.giftDetails.experience &&
              props.giftDetails.experience.greeting_card &&
              props.giftDetails.experience.greeting_card.uploaded_video
            }
            setShowVideo={setShowVideo}
            fluidimage={props.backimage}
            defaultgreeting={`Greetings!!`}
          />
          <GreetingCardBackStillV2
            openStatus={openStatus}
            setGiftOpen={props.setGiftOpen}
            setShowGift={props.setShowGift}
            greetingcard={props.greetingcard}
            setStartMusic={setStartMusic}
          >
            {props.children}
          </GreetingCardBackStillV2>
        </GreetingCardContainerV2>
      </GreetingCardContainerWrapperV2>
      {showVideo && (
        <VideoModal
          {...props}
          showVideo={showVideo}
          setShowVideo={setShowVideo}
        />
      )}
    </>
  )
}
