import React, { useState } from "react"
import styled from "styled-components"

function playMusic() {
  const tune = document.querySelector(`.selected-audio-tune`)
  if (tune) {
    tune.currentTime = 0
    tune.play()
  }
}

function pauseMusic() {
  const tune = document.querySelector(`.selected-audio-tune`)
  if (tune) {
    tune.pause()
  }
}

const PlayButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #2d2d2d10;
  box-shadow: inset 0 0 10px 0 #2d2d2d30;
  z-index: 99;
`
const PlayButton = styled.button`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #14a0b3;
  border: none;
  outline: none;
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  transition: transform 0.3s ease-in;

  &::before {
    content: "";
    width: 0;
    height: 0;
    border-top: 25px solid transparent;
    border-right: none;
    border-bottom: 25px solid transparent;
    border-left: 37.5px solid #2d2d2d;
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 12.5px);
    transition: transform 0.2s ease-in;
  }
  &:hover {
    transform: scale(0.9);
  }
  &:hover::before {
    transform: scale(0.9);
  }
`

export default function StartExperienceButton(props) {
  const [playStatus, setPlayStatus] = useState(false)

  return (
    <>
      {!playStatus && (
        <PlayButtonWrapper>
          <PlayButton
            type="button"
            onClick={() => {
              setPlayStatus(!playStatus)
              props.setStartExperience(true)
              setTimeout(() => {
                if (props.audioTune) {
                  !playStatus ? playMusic() : pauseMusic()
                }
              }, 100)
            }}
          ></PlayButton>
        </PlayButtonWrapper>
      )}
    </>
  )
}
