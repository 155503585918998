import React, { Fragment, useState, useEffect } from "react"
import styled from "styled-components"
import ShowLoadingComponent from "./LoadingComponent"
import StartExperienceButton from "./StartExperienceButton"
import ReplayButton, { ReplayButtonV2 } from "./ReplayButton"
import AnimationView from "./AnimationView"
import GreetingCardAnimated, {
  GreetingCardAnimatedV2,
} from "./GreetingCardAnimated"
import FlapBox from "./Flapbox"
import PaperWrapper from "./PaperWrapper"

const ButtonBlock = styled.div`
  padding: 0 1rem;
`
const BackButtonWrapper = styled.div`
  float: left;
`
const ReplayButtonWrapper = styled.div`
  float: right;
  margin-right: 1em;
`
const ExperienceWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 600px;
  display: grid;
  justify-items: center;
  align-items: center;
  overflow: hidden;
  @media (max-width: 600px) {
    width: 550px;
  }
  @media (max-width: 550px) {
    width: 500px;
  }
  @media (max-width: 500px) {
    height: 400px;
    width: 450px;
    margin: 0 auto;
  }
  @media (max-width: 450px) {
    width: 400px;
  }
  @media (max-width: 400px) {
    width: 350px;
  }
  @media (max-width: 350px) {
    width: 300px;
  }
  @media (max-width: 300px) {
    width: 250px;
  }
`
const ExperienceWrapperV2 = styled.div`
  position: relative;
  width: 100%;
  min-height: 600px;
  display: grid;
  justify-items: center;
  align-items: center;
  overflow: hidden;
  /* @media (max-width: 600px) {
    width: 550px;
  }
  @media (max-width: 550px) {
    width: 500px;
  }
  @media (max-width: 500px) {
    height: 400px;
    width: 450px;
    margin: 0 auto;
  }
  @media (max-width: 450px) {
    width: 400px;
  }
  @media (max-width: 400px) {
    width: 350px;
  }
  @media (max-width: 350px) {
    width: 300px;
  }
  @media (max-width: 300px) {
    width: 250px;
  } */
`
const GreetingCardWrapper = styled.div`
  position: relative;
  width: 100%;
  display: ${props => (props.giftOpen ? "none" : "block")};
`
const GreetingCardWrapperV2 = styled.div`
  position: relative;
  width: 100%;
  display: ${props => (props.giftOpen ? "none" : "block")};
`
const GreetingMessageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  justify-items: center;
  align-items: center;
`
const GreetingMessageText = styled.div`
  font-family: Futura Book Regular;
  font-size: 1rem;
  @media (max-width: 768px) {
    font-size: 0.75rem;
  }
`
const GiftOpeningWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const GiftOpeningWrapperV2 = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export default function Experience(props) {
  const [showAnimation, setShowAnimation] = useState(true)
  const [showGreeting, setShowGreeting] = useState(false)
  const [openGreeting, setOpenGreeting] = useState(false)
  const [showGift, setShowGift] = useState(false)
  const [giftOpen, setGiftOpen] = useState(false)
  const [videoSrc, setVideoSrc] = useState(null)
  const [showLoading, setShowLoading] = useState(true)
  const [errorFetchingAnimation, setErrorFetchingAnimation] = useState(false)
  const [startExperience, setStartExperience] = useState(false)

  useEffect(() => {
    if (!props.giftDetails.experience.animation) {
      setErrorFetchingAnimation(true)
      setShowLoading(false)
      if (!props.giftDetails.experience.greeting_card) {
        setShowGift(true)
        setGiftOpen(true)
      }
    }
  }, [])

  return (
    <Fragment>
      <ButtonBlock>
        <BackButtonWrapper />
        {props.giftDetails &&
          props.giftDetails.experience &&
          props.giftDetails.experience.status && (
            <ReplayButtonWrapper>
              {giftOpen && (
                <ReplayButton
                  setShowAnimation={setShowAnimation}
                  setShowGreeting={setShowGreeting}
                  setOpenGreeting={setOpenGreeting}
                  setGiftOpen={setGiftOpen}
                />
              )}
            </ReplayButtonWrapper>
          )}
      </ButtonBlock>
      <ExperienceWrapper>
        {showAnimation && (
          <AnimationView
            {...props}
            startExperience={startExperience}
            videoSrc={videoSrc}
            setVideoSrc={setVideoSrc}
            setGiftOpen={setGiftOpen}
            setShowGift={setShowGift}
            showAnimation={showAnimation}
            setShowAnimation={setShowAnimation}
            setOpenGreeting={setOpenGreeting}
            setShowGreeting={setShowGreeting}
            showLoading={showLoading}
            setShowLoading={setShowLoading}
            errorFetchingAnimation={errorFetchingAnimation}
          />
        )}
        {showGreeting && props.greetingcard && openGreeting && (
          <GreetingCardWrapper giftOpen={giftOpen}>
            <GreetingCardAnimated
              startExperience={startExperience}
              setShowGift={setShowGift}
              setGiftOpen={setGiftOpen}
              openGreeting={openGreeting}
              frontimage={props.greetingcard.product_image}
              backimage={props.greetingcard.product_inner_image}
              greetingcard={props.greetingcard}
              openclicked={() => null}
              closeclicked={() => {
                setGiftOpen(true)
              }}
            >
              <GreetingMessageWrapper>
                <GreetingMessageText>
                  {props.greetingMessage || ""}
                </GreetingMessageText>
              </GreetingMessageWrapper>
            </GreetingCardAnimated>
            <span id="personalised-gift-opening"></span>
          </GreetingCardWrapper>
        )}
        {showGift && (
          <GiftOpeningWrapper>
            {giftOpen && (
              <FlapBox
                startExperience={startExperience}
                autoplay={false}
                data={props.data}
                giftcard={props.giftcard}
                giftwrapper={
                  props.giftwrapper ? props.giftwrapper.product_image : null
                }
              />
            )}
          </GiftOpeningWrapper>
        )}
        {props.audioTune && (
          <audio
            className="selected-audio-tune"
            src={`${process.env.GATSBY_EGIFTIT_B2B}${props.audioTune.product_file}`}
          ></audio>
        )}
        {(props.giftwrapper ||
          props.greetingcard ||
          props.audioTune ||
          props.fetchedAnimation) && (
          <Fragment>
            {showLoading ? (
              <ShowLoadingComponent />
            ) : (
              <StartExperienceButton
                setStartExperience={setStartExperience}
                audioTune={props.audioTune}
              />
            )}
          </Fragment>
        )}
      </ExperienceWrapper>
    </Fragment>
  )
}

export function ExperienceV2(props) {
  const [showAnimation, setShowAnimation] = useState(true)
  const [showGreeting, setShowGreeting] = useState(false)
  const [openGreeting, setOpenGreeting] = useState(false)
  const [showGift, setShowGift] = useState(false)
  const [giftOpen, setGiftOpen] = useState(false)
  const [videoSrc, setVideoSrc] = useState(null)
  const [showLoading, setShowLoading] = useState(true)
  const [errorFetchingAnimation, setErrorFetchingAnimation] = useState(false)
  const [startExperience, setStartExperience] = useState(false)

  useEffect(() => {
    setShowLoading(false)
    const loadTimer = setTimeout(() => {
      if (!props.giftDetails.experience.animation) {
        setErrorFetchingAnimation(true)
        if (!props.giftDetails.experience.greeting_card) {
          setShowGift(true)
          setGiftOpen(true)
        }
      }
    }, 1000)
    return () => {
      clearTimeout(loadTimer)
    }
  }, [])

  function playMusic() {
    const tune = document.querySelector(`.selected-audio-tune`)
    if (tune) {
      tune.currentTime = 0
      tune.play()
    }
  }

  function pauseMusic() {
    const tune = document.querySelector(`.selected-audio-tune`)
    if (tune) {
      tune.pause()
    }
  }

  return (
    <Fragment>
      <ButtonBlock>
        <BackButtonWrapper />
        {props.giftDetails &&
          props.giftDetails.experience &&
          props.giftDetails.experience.status && (
            <ReplayButtonWrapper>
              {giftOpen && startExperience && (
                <ReplayButtonV2
                  animation={
                    props.giftDetails &&
                    props.giftDetails.experience &&
                    props.giftDetails.experience.animation
                  }
                  greetingcard={props.greetingcard}
                  setShowGreeting={setShowGreeting}
                  setOpenGreeting={setOpenGreeting}
                  setGiftOpen={setGiftOpen}
                  setShowAnimation={setShowAnimation}
                />
              )}
            </ReplayButtonWrapper>
          )}
      </ButtonBlock>
      <ExperienceWrapperV2>
        {showAnimation && (
          <AnimationView
            {...props}
            startExperience={startExperience}
            videoSrc={videoSrc}
            setVideoSrc={setVideoSrc}
            setGiftOpen={setGiftOpen}
            setShowGift={setShowGift}
            showAnimation={showAnimation}
            setShowAnimation={setShowAnimation}
            setOpenGreeting={setOpenGreeting}
            setShowGreeting={setShowGreeting}
            showLoading={showLoading}
            setShowLoading={setShowLoading}
            errorFetchingAnimation={errorFetchingAnimation}
          />
        )}
        {showGreeting && props.greetingcard && (
          <GreetingCardWrapperV2 giftOpen={giftOpen}>
            <GreetingCardAnimatedV2
              {...props}
              startExperience={startExperience}
              setShowGift={setShowGift}
              setGiftOpen={setGiftOpen}
              openGreeting={openGreeting}
              frontimage={props.greetingcard.product_image}
              backimage={props.greetingcard.product_inner_image}
              greetingcard={props.greetingcard}
              openclicked={() => null}
              closeclicked={() => {
                setGiftOpen(true)
              }}
            >
              <GreetingMessageWrapper>
                <GreetingMessageText>
                  {props.greetingMessage || ""}
                </GreetingMessageText>
              </GreetingMessageWrapper>
            </GreetingCardAnimatedV2>
            <span id="personalised-gift-opening"></span>
          </GreetingCardWrapperV2>
        )}
        {showGift && (
          <GiftOpeningWrapper>
            {giftOpen && (
              <PaperWrapper
                startExperience={startExperience}
                autoplay={false}
                data={props.data}
                giftcard={props.giftcard}
                giftwrapper={
                  props.giftwrapper ? props.giftwrapper.product_image : null
                }
              />
            )}
          </GiftOpeningWrapper>
        )}
        {props.audioTune && (
          <audio
            className="selected-audio-tune"
            src={`${process.env.GATSBY_EGIFTIT_B2B}${props.audioTune.product_file}`}
          ></audio>
        )}
        {(props.giftwrapper ||
          props.greetingcard ||
          props.audioTune ||
          props.fetchedAnimation) && (
          <Fragment>
            {showLoading ? (
              <ShowLoadingComponent />
            ) : (
              <StartExperienceButton
                setStartExperience={setStartExperience}
                audioTune={props.audioTune}
              />
            )}
          </Fragment>
        )}
      </ExperienceWrapperV2>
    </Fragment>
  )
}
