import React, { Component, Fragment } from "react"
import styled, { keyframes } from "styled-components"
import CanvasConfetti from "../components/CanvasConfetti"

const bounce7 = keyframes`
  0%   { transform: scale(1,1)      translateY(0); }
  10%  { transform: scale(1.1,.9)   translateY(0); }
  30%  { transform: scale(.9,1.1)   translateY(-100px); }
  50%  { transform: scale(1.05,.95) translateY(0); }
  57%  { transform: scale(1,1)      translateY(-7px); }
  64%  { transform: scale(1,1)      translateY(0); }
  100% { transform: scale(1,1)      translateY(0); }
`

const RightTriangleDiv = styled.div`
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
`
const RightTriangleFront = styled.div`
  transition: all ${props => props.delay}ms ease-in;
`
const RightTriangleFrontLeft = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 50%;
  height: 100%;
  backface-visibility: hidden;
`
const RightTriangleFrontRight = styled.div`
  display: inline-block;
  vertical-align: top;
  z-index: 900;
  width: 50%;
  height: 100%;
  backface-visibility: hidden;
`
const RightTriangleBack = styled.div`
  transition: all ${props => props.delay}ms ease-in;
`
const RightTriangleBackLeft = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 50%;
  height: 100%;
  backface-visibility: hidden;
`
const RightTriangleBackRight = styled.div`
  display: inline-block;
  vertical-align: top;
  z-index: 800;
  width: 50%;
  height: 100%;
  backface-visibility: hidden;
`
const RightTriangleBackRightSVG = styled.svg`
  transition: all ${props => props.delay}ms ease-in;
`
class RightTriangle extends Component {
  render() {
    const wrapperwidth = this.props.wrapperwidth.slice(0, -2)
    const svgWidth = this.props.width.slice(0, -2)
    const svgHeight = this.props.height.slice(0, -2)
    // width 400
    // height 220
    const triWidth = Number(svgWidth) / 4
    const triMid = Number(svgHeight) / 2
    const polygonPoints = `0, 0, ${triWidth}, ${triMid}, 0 , ${svgHeight}`
    // console.log(svgWidth)
    // console.log(svgHeight)
    return (
      <RightTriangleDiv className="right">
        <RightTriangleFront
          className="right-triangle-front"
          delay={this.props.delay}
        >
          <RightTriangleFrontLeft className="front-left"></RightTriangleFrontLeft>
          <RightTriangleFrontRight className="front-right"></RightTriangleFrontRight>
        </RightTriangleFront>
        <RightTriangleBack
          className="right-triangle-back"
          delay={this.props.delay}
        >
          <RightTriangleBackLeft className="back-left"></RightTriangleBackLeft>
          <RightTriangleBackRight className="back-right">
            <RightTriangleBackRightSVG
              width={svgWidth}
              height={svgHeight}
              delay={this.props.delay}
              className="right-triangle-svg"
            >
              <defs>
                <clipPath id="triangleView">
                  <polygon points={polygonPoints} />
                </clipPath>
              </defs>
              <image
                xlinkHref={this.props.wrapperimage}
                clipPath="url(#triangleView)"
                width={895}
                height={wrapperwidth}
              />
            </RightTriangleBackRightSVG>
            <LeftDownTriangle
              width={this.props.width}
              height={this.props.height}
              delay={this.props.delay}
              ffcolor={this.props.ffcolor}
              bfcolor={this.props.bfcolor}
              wrapperimage={this.props.wrapperimage}
              wrapperwidth={wrapperwidth}
            />
            <LeftUpTriangle
              width={this.props.width}
              height={this.props.height}
              delay={this.props.delay}
              ffcolor={this.props.ffcolor}
              bfcolor={this.props.bfcolor}
              wrapperimage={this.props.wrapperimage}
              wrapperwidth={wrapperwidth}
            />
          </RightTriangleBackRight>
        </RightTriangleBack>
      </RightTriangleDiv>
    )
  }
}

const LeftTriangleDiv = styled.div`
  width: 50%;
  height: 100%;
  position: absolute;
  right: -25%;
  top: 0;
  z-index: 1000;
`
const LeftTriangleFront = styled.div`
  transition: all ${props => props.delay}ms ease-in;
`
const LeftTriangleFrontLeft = styled.div`
  display: inline-block;
  vertical-align: top;
  z-index: 900;
  width: 50%;
  height: 100%;
  backface-visibility: hidden;
`
const LeftTriangleFrontRight = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 50%;
  height: 100%;
  backface-visibility: hidden;
`
const LeftTriangleBack = styled.div`
  transition: all ${props => props.delay}ms ease-in;
`
const LeftTriangleBackLeft = styled.div`
  display: inline-block;
  vertical-align: top;
  z-index: 800;
  width: 50%;
  height: 100%;
  backface-visibility: hidden;
`
const LeftTriangleBackRight = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 50%;
  height: 100%;
  backface-visibility: hidden;
`

class LeftTriangle extends Component {
  render() {
    const wrapperwidth = this.props.wrapperwidth.slice(0, -2)
    const svgWidth = this.props.width.slice(0, -2)
    const svgHeight = this.props.height.slice(0, -2)
    // width 400
    // height 220
    // "100, 0, 0, 110, 100, 220"
    const triWidth = Number(svgWidth) / 4
    const triMid = Number(svgHeight) / 2
    const polygonPoints = `${triWidth}, 0, 0, ${triMid}, ${triWidth}, ${svgHeight}`
    return (
      <LeftTriangleDiv className="left">
        <LeftTriangleFront
          className="left-triangle-front"
          delay={this.props.delay}
        >
          <LeftTriangleFrontLeft className="front-left"></LeftTriangleFrontLeft>
          <LeftTriangleFrontRight className="front-right"></LeftTriangleFrontRight>
        </LeftTriangleFront>
        <LeftTriangleBack
          className="left-triangle-back"
          delay={this.props.delay}
        >
          <LeftTriangleBackLeft className="back-left">
            <svg
              width={svgWidth}
              height={svgHeight}
              className="left-triangle-svg"
            >
              <defs>
                <clipPath id="lefttriangleView">
                  <polygon points={polygonPoints} />
                </clipPath>
              </defs>
              <image
                xlinkHref={this.props.wrapperimage}
                clipPath="url(#lefttriangleView)"
                width={895}
                height={wrapperwidth}
              />
            </svg>
            <RightDownTriangle
              width={this.props.width}
              height={this.props.height}
              delay={this.props.delay}
              ffcolor={this.props.ffcolor}
              bfcolor={this.props.bfcolor}
              wrapperimage={this.props.wrapperimage}
              wrapperwidth={wrapperwidth}
            />
            <RightUpTriangle
              width={this.props.width}
              height={this.props.height}
              delay={this.props.delay}
              ffcolor={this.props.ffcolor}
              bfcolor={this.props.bfcolor}
              wrapperimage={this.props.wrapperimage}
              wrapperwidth={wrapperwidth}
            />
          </LeftTriangleBackLeft>
          <LeftTriangleBackRight className="back-right"></LeftTriangleBackRight>
        </LeftTriangleBack>
      </LeftTriangleDiv>
    )
  }
}

const LeftDownTriangleDiv = styled.div`
  position: absolute;
  top: 0;
  right: 50%;
`
const LeftDownTriangleFront = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 25%;
  height: 50%;
  transition: all ${props => props.delay}ms ease-in;
`
const LeftDownTriangleBack = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 25%;
  height: 50%;
  transition: all ${props => props.delay}ms ease-in;
`

class LeftDownTriangle extends Component {
  render() {
    const wrapperwidth = this.props.wrapperwidth
    const svgWidth = Number(this.props.width.slice(0, -2)) / 2
    const svgHeight = Number(this.props.height.slice(0, -2)) / 2
    // width 400
    // height 220
    // "100, 110, 0, 110, 0, 0"
    const triWidth = Number(svgWidth) / 2
    const triMid = Number(svgHeight)
    const polygonPoints = `${triWidth}, ${triMid}, 0, ${triMid}, 0, 0`
    return (
      <LeftDownTriangleDiv className="left-down">
        <LeftDownTriangleFront
          className="left-down-triangle-front"
          delay={this.props.delay}
        >
          <svg width={`${svgWidth}`} height={`${svgHeight}`}>
            <defs>
              <clipPath id="leftdowntriangleView1">
                <polygon points={polygonPoints} />
              </clipPath>
            </defs>
            <image
              xlinkHref={this.props.wrapperimage}
              clipPath="url(#leftdowntriangleView1)"
              width={895}
              height={wrapperwidth}
            />
          </svg>
        </LeftDownTriangleFront>
        <LeftDownTriangleBack
          className="left-down-triangle-back"
          delay={this.props.delay}
        >
          <svg width={svgWidth} height={svgHeight}>
            <defs>
              <clipPath id="leftdowntriangleView2">
                <polygon points={polygonPoints} />
              </clipPath>
            </defs>
            <image
              xlinkHref={this.props.wrapperimage}
              clipPath="url(#leftdowntriangleView2)"
              width={895}
              height={wrapperwidth}
            />
          </svg>
        </LeftDownTriangleBack>
      </LeftDownTriangleDiv>
    )
  }
}

const LeftUpTriangleDiv = styled.div`
  position: absolute;
  top: 50%;
  right: 50%;
`
const LeftUpTriangleFront = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: ${props => props.divWidth}px;
  height: ${props => props.svgHeight}px;
  transition: all ${props => props.delay}ms ease-in;
`
const LeftUpTriangleBack = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: ${props => props.divWidth}px;
  height: ${props => props.svgHeight}px;
  transition: all ${props => props.delay}ms ease-in;
`

class LeftUpTriangle extends Component {
  render() {
    const wrapperwidth = this.props.wrapperwidth
    const svgWidth = Number(this.props.width.slice(0, -2)) / 2
    const svgHeight = Number(this.props.height.slice(0, -2)) / 2
    const divWidth = svgWidth / 2
    // width 400
    // height 220
    // "100, 0, 0, 110, 0, 0"
    const triWidth = Number(svgWidth) / 2
    const triMid = Number(svgHeight)
    const polygonPoints = `${triWidth}, 0, 0, ${triMid}, 0, 0`
    return (
      <LeftUpTriangleDiv className="left-up">
        <LeftUpTriangleFront
          className="left-up-triangle-front"
          divWidth={divWidth}
          svgHeight={svgHeight}
          delay={this.props.delay}
        >
          <svg width={`${svgWidth}`} height={`${svgHeight}`}>
            <defs>
              <clipPath id="leftuptriangleView1">
                <polygon points={polygonPoints} />
              </clipPath>
            </defs>
            <image
              xlinkHref={this.props.wrapperimage}
              clipPath="url(#leftuptriangleView1)"
              width={895}
              height={wrapperwidth}
            />
          </svg>
        </LeftUpTriangleFront>
        <LeftUpTriangleBack
          className="left-up-triangle-back"
          divWidth={divWidth}
          svgHeight={svgHeight}
          delay={this.props.delay}
        >
          <svg width={`${svgWidth}`} height={`${svgHeight}`}>
            <defs>
              <clipPath id="leftuptriangleView2">
                <polygon points={polygonPoints} />
              </clipPath>
            </defs>
            <image
              xlinkHref={this.props.wrapperimage}
              clipPath="url(#leftuptriangleView2)"
              width={895}
              height={wrapperwidth}
            />
          </svg>
        </LeftUpTriangleBack>
      </LeftUpTriangleDiv>
    )
  }
}

const RightDownTriangleDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transition: all ${props => props.delay}ms ease-in;
`
const RightDownTriangleFront = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 900;
  width: ${props => props.divWidth}px;
  height: ${props => props.svgHeight}px;
  transition: all ${props => props.delay}ms ease-in;
`
const RightDownTriangleBack = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: ${props => props.divWidth}px;
  height: ${props => props.svgHeight}px;
  transition: all ${props => props.delay}ms ease-in;
`

class RightDownTriangle extends Component {
  render() {
    const wrapperwidth = this.props.wrapperwidth
    const svgWidth = Number(this.props.width.slice(0, -2)) / 2
    const svgHeight = Number(this.props.height.slice(0, -2)) / 2
    const divWidth = svgWidth / 2
    // width 400
    // height 220
    // "100, 0, 100, 110, 0, 110"
    const triWidth = Number(svgWidth) / 2
    const triMid = Number(svgHeight)
    const polygonPoints = `${triWidth}, 0, ${triWidth}, ${triMid}, 0, ${triMid}`
    return (
      <RightDownTriangleDiv className="right-down" delay={this.props.delay}>
        <RightDownTriangleFront
          className="right-down-triangle-front"
          divWidth={divWidth}
          svgHeight={svgHeight}
          delay={this.props.delay}
        >
          <svg width={`${svgWidth}`} height={`${svgHeight}`}>
            <defs>
              <clipPath id="rightdowntriangleView1">
                <polygon points={polygonPoints} />
              </clipPath>
            </defs>
            <image
              xlinkHref={this.props.wrapperimage}
              clipPath="url(#rightdowntriangleView1)"
              width={895}
              height={wrapperwidth}
            />
          </svg>
        </RightDownTriangleFront>
        <RightDownTriangleBack
          className="right-down-triangle-back"
          divWidth={divWidth}
          svgHeight={svgHeight}
          delay={this.props.delay}
        >
          <svg width={`${svgWidth}`} height={`${svgHeight}`}>
            <defs>
              <clipPath id="rightdowntriangleView2">
                <polygon points={polygonPoints} />
              </clipPath>
            </defs>
            <image
              xlinkHref={this.props.wrapperimage}
              clipPath="url(#rightdowntriangleView2)"
              width={895}
              height={wrapperwidth}
            />
          </svg>
        </RightDownTriangleBack>
      </RightDownTriangleDiv>
    )
  }
}

const RightUpTriangleDiv = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
`
const RightUpTriangleFront = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 25%;
  height: 50%;
  transition: all ${props => props.delay}ms ease-in;
`
const RightUpTriangleBack = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 25%;
  height: 50%;
  transition: all ${props => props.delay}ms ease-in;
`

class RightUpTriangle extends Component {
  render() {
    const wrapperwidth = this.props.wrapperwidth
    const svgWidth = Number(this.props.width.slice(0, -2)) / 2
    const svgHeight = Number(this.props.height.slice(0, -2)) / 2
    // width 400
    // height 220
    // "0, 0, 100, 0, 100, 110"
    const triWidth = Number(svgWidth) / 2
    const triMid = Number(svgHeight)
    const polygonPoints = `0, 0, ${triWidth}, 0, ${triWidth}, ${triMid}`
    return (
      <RightUpTriangleDiv className="right-up">
        <RightUpTriangleFront
          className="right-up-triangle-front"
          delay={this.props.delay}
        >
          <svg width={`${svgWidth}`} height={`${svgHeight}`}>
            <defs>
              <clipPath id="rightuptriangleView1">
                <polygon points={polygonPoints} />
              </clipPath>
            </defs>
            <image
              xlinkHref={this.props.wrapperimage}
              clipPath="url(#rightuptriangleView1)"
              width={895}
              height={wrapperwidth}
            />
          </svg>
        </RightUpTriangleFront>
        <RightUpTriangleBack
          className="right-up-triangle-back"
          delay={this.props.delay}
        >
          <svg width={`${svgWidth}`} height={`${svgHeight}`}>
            <defs>
              <clipPath id="rightuptriangleView2">
                <polygon points={polygonPoints} />
              </clipPath>
            </defs>
            <image
              xlinkHref={this.props.wrapperimage}
              clipPath="url(#rightuptriangleView2)"
              width={895}
              height={wrapperwidth}
            />
          </svg>
        </RightUpTriangleBack>
      </RightUpTriangleDiv>
    )
  }
}

const RectangleUpDiv = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};
  position: relative;
  top: -50%;
  perspective: 100vw;
  perspective-origin: center center;
`
const RectangleUpFront = styled.div`
  transition: all ${props => props.delay}ms ease-in;
`
const RectangleUpFrontTop = styled.div`
  width: 100%;
  height: 50%;
  background: none;
  backface-visibility: hidden;
`
const RectangleUpFrontBottom = styled.div`
  width: 100%;
  height: 50%;
  backface-visibility: hidden;
  background: ${props => props.ffcolor};
`
const RectangleUpBack = styled.div`
  transition: all ${props => props.delay}ms ease-in;
`
const RectangleUpBackTop = styled.div`
  width: 100%;
  height: 50%;
  background: none;
  backface-visibility: hidden;
`
const RectangleUpBackBottom = styled.div`
  width: 100%;
  height: 50%;
  backface-visibility: hidden;
  background: ${props => props.bfcolor};
`

class RectangleUp extends Component {
  render() {
    const { width, height } = this.props
    return (
      <RectangleUpDiv className="rect-up" width={width} height={height}>
        <RectangleUpFront className="rect-up-front" delay={this.props.delay}>
          <RectangleUpFrontTop className="front-top"></RectangleUpFrontTop>
          <RectangleUpFrontBottom
            className="front-bottom"
            ffcolor={this.props.ffcolor}
          ></RectangleUpFrontBottom>
        </RectangleUpFront>
        <RectangleUpBack className="rect-up-back" delay={this.props.delay}>
          <RectangleUpBackTop className="back-top"></RectangleUpBackTop>
          <RectangleUpBackBottom
            className="back-bottom"
            bfcolor={this.props.bfcolor}
          ></RectangleUpBackBottom>
        </RectangleUpBack>
      </RectangleUpDiv>
    )
  }
}

const RectangleDownDiv = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};
  position: absolute;
  top: 50%;
  perspective: 100vw;
  perspective-origin: center center;
`
const RectangleDownFront = styled.div`
  transition: all ${props => props.delay}ms ease-in;
`
const RectangleDownFrontTop = styled.div`
  width: 100%;
  height: 50%;
  backface-visibility: hidden;
  background: ${props => props.ffcolor};
`
const RectangleDownFrontBottom = styled.div`
  width: 100%;
  height: 50%;
  background: none;
  backface-visibility: hidden;
`
const RectangleDownBack = styled.div`
  transition: all ${props => props.delay}ms ease-in;
`
const RectangleDownBackTop = styled.div`
  width: 100%;
  height: 50%;
  backface-visibility: hidden;
  background: ${props => props.bfcolor};
`
const RectangleDownBackBottom = styled.div`
  width: 100%;
  height: 50%;
  background: none;
  backface-visibility: hidden;
`

class RectangleDown extends Component {
  render() {
    const { width, height } = this.props
    return (
      <RectangleDownDiv className="rect-down" width={width} height={height}>
        <RectangleDownFront
          className="rect-down-front"
          delay={this.props.delay}
        >
          <RectangleDownFrontTop
            className="front-top"
            ffcolor={this.props.ffcolor}
          ></RectangleDownFrontTop>
          <RectangleDownFrontBottom className="front-bottom"></RectangleDownFrontBottom>
        </RectangleDownFront>
        <RectangleDownBack className="rect-down-back" delay={this.props.delay}>
          <RectangleDownBackTop
            className="back-top"
            bfcolor={this.props.bfcolor}
          ></RectangleDownBackTop>
          <RectangleDownBackBottom className="back-bottom"></RectangleDownBackBottom>
        </RectangleDownBack>
      </RectangleDownDiv>
    )
  }
}

const RectangleMaskLeftDiv = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};
  position: absolute;
  top: 50%;
`
const RectangleMaskLeftInner = styled.div`
  position: absolute;
  top: -50%;
  left: 0;
  width: 50%;
  height: 100%;
  transform: scale(-1);
  background: ${props => props.ffcolor};
`

class RectangleMaskLeft extends Component {
  render() {
    const { width, height } = this.props
    return (
      <RectangleMaskLeftDiv
        className="rect-mask-left"
        width={width}
        height={height}
      >
        <RectangleMaskLeftInner
          className="rect-mask-left-inner"
          ffcolor={this.props.ffcolor}
        ></RectangleMaskLeftInner>
      </RectangleMaskLeftDiv>
    )
  }
}

const RectangleMaskRightDiv = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};
  position: absolute;
  top: 50%;
`
const RectangleMaskRightInner = styled.div`
  position: absolute;
  top: -50%;
  left: 50%;
  width: 50%;
  height: 100%;
  transform: scale(-1);
  background: ${props => props.ffcolor};
`

class RectangleMaskRight extends Component {
  render() {
    const { width, height } = this.props
    return (
      <RectangleMaskRightDiv
        className="rect-mask-right"
        width={width}
        height={height}
      >
        <RectangleMaskRightInner
          className="rect-mask-right-inner"
          ffcolor={this.props.ffcolor}
        ></RectangleMaskRightInner>
      </RectangleMaskRightDiv>
    )
  }
}

const RectangleRightDiv = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};
  position: absolute;
  top: 0px;
  left: ${props => props.leftShift}px;
  perspective: 100vw;
  perspective-origin: center center;
`
const RectangleRightFront = styled.div`
  transition: all ${props => props.delay}ms ease-in;
`
const RectangleRightFrontLeft = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 50%;
  height: 100%;
  background: ${props => props.ffcolor};
`
const RectangleRightFrontRight = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 50%;
  height: 100%;
  background: none;
`
const RectangleRightBack = styled.div`
  transition: all ${props => props.delay}ms ease-in;
`
const RectangleRightBackLeft = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 50%;
  height: 100%;
  background: ${props => props.bfcolor};
`
const RectangleRightBackRight = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 50%;
  height: 100%;
  background: none;
`

class RectangleRight extends Component {
  render() {
    const { width, height } = this.props
    const leftShift = Number(width.slice(0, -2)) / 2
    return (
      <RectangleRightDiv
        className="rect-right"
        width={width}
        height={height}
        leftShift={leftShift}
      >
        <RectangleRightFront
          className="rect-right-front"
          delay={this.props.delay}
        >
          <RectangleRightFrontLeft
            className="front-left"
            ffcolor={this.props.ffcolor}
          ></RectangleRightFrontLeft>
          <RectangleRightFrontRight className="front-right"></RectangleRightFrontRight>
        </RectangleRightFront>
        <RectangleRightBack
          className="rect-right-back"
          delay={this.props.delay}
        >
          <RectangleRightBackLeft
            className="back-left"
            bfcolor={this.props.bfcolor}
          ></RectangleRightBackLeft>
          <RectangleRightBackRight className="back-right"></RectangleRightBackRight>
        </RectangleRightBack>
      </RectangleRightDiv>
    )
  }
}

const RectangleLeftDiv = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};
  position: absolute;
  top: 0;
  left: -${props => props.leftShift}px;
  perspective: 100vw;
  perspective-origin: center center;
`
const RectangleLeftFront = styled.div`
  transition: all ${props => props.delay}ms ease-in;
`
const RectangleLeftFrontLeft = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 50%;
  height: 100%;
  background: none;
`
const RectangleLeftFrontRight = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 50%;
  height: 100%;
  background: ${props => props.ffcolor};
`
const RectangleLeftBack = styled.div`
  transition: all ${props => props.delay}ms ease-in;
`
const RectangleLeftBackLeft = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 50%;
  height: 100%;
  background: none;
`
const RectangleLeftBackRight = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 50%;
  height: 100%;
  background: ${props => props.bfcolor};
`

class RectangleLeft extends Component {
  render() {
    const { width, height } = this.props
    const leftShift = Number(width.slice(0, -2)) / 2
    return (
      <RectangleLeftDiv
        className="rect-left"
        width={width}
        height={height}
        leftShift={leftShift}
      >
        <RectangleLeftFront
          className="rect-left-front"
          delay={this.props.delay}
        >
          <RectangleLeftFrontLeft className="front-left"></RectangleLeftFrontLeft>
          <RectangleLeftFrontRight
            className="front-right"
            ffcolor={this.props.ffcolor}
          ></RectangleLeftFrontRight>
        </RectangleLeftFront>
        <RectangleLeftBack className="rect-left-back" delay={this.props.delay}>
          <RectangleLeftBackLeft className="back-left"></RectangleLeftBackLeft>
          <RectangleLeftBackRight
            className="back-right"
            bfcolor={this.props.bfcolor}
          ></RectangleLeftBackRight>
        </RectangleLeftBack>
      </RectangleLeftDiv>
    )
  }
}

const EnvelopeBackDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: ${props => props.width};
  height: ${props => props.height};
`

class EnvelopeBack extends Component {
  render() {
    const {
      width,
      height,
      delay,
      ffcolor,
      bfcolor,
      wrapperimage,
      wrapperwidth,
    } = this.props
    return (
      <EnvelopeBackDiv className="envelope-back" width={width} height={height}>
        <RightTriangle
          width={width}
          height={height}
          delay={delay}
          ffcolor={ffcolor}
          bfcolor={bfcolor}
          wrapperimage={wrapperimage}
          wrapperwidth={wrapperwidth}
        />
        <LeftTriangle
          width={width}
          height={height}
          delay={delay}
          ffcolor={ffcolor}
          bfcolor={bfcolor}
          wrapperimage={wrapperimage}
          wrapperwidth={wrapperwidth}
        />
        <RectangleMaskLeft
          width={width}
          height={height}
          delay={delay}
          ffcolor={ffcolor}
        />
        <RectangleMaskRight
          width={width}
          height={height}
          delay={delay}
          ffcolor={ffcolor}
        />
        <RectangleUp
          width={width}
          height={height}
          delay={delay}
          ffcolor={ffcolor}
        />
        <RectangleDown
          width={width}
          height={height}
          delay={delay}
          ffcolor={ffcolor}
        />
      </EnvelopeBackDiv>
    )
  }
}

const RectFlipDiv = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};
  position: relative;
  top: 25%;
  left: ${props => props.leftShift}px;
  perspective: 100vw;
  perspective-origin: center center;
  @media (max-width: 700px) {
    left: 0;
  }
`
const RectFlipFront = styled.div`
  transition: all ${props => props.delay}ms ease-in;
`
const RectFlipFrontTop = styled.div`
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  background: ${props => props.ffcolor};
`
const RectFlipBack = styled.div`
  transition: all ${props => props.delay}ms ease-in;
`

class EnvelopeFront extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mouseDown: false,
      mouseOut: false,
      posY: 0,
    }
  }

  _handleDragStart = e => {
    // e.preventDefault()
    this.setState({ mouseDown: true, mouseOut: false, posY: e.clientY })
  }

  _handleMouseOut = e => {
    // e.preventDefault()
    const posY = e.clientY
    if (this.state.mouseDown) {
      this.setState({ mouseOut: true, mouseDown: false }, () => {
        if (this.state.posY > posY) {
          // this.props.onOpen("up")
        } else {
          // this.props.onOpen("down")
        }
      })
    }
  }

  _handleTouchStart = changedTouches => {
    const posY = changedTouches.screenY
    this.setState({ mouseDown: true, mouseOut: false, posY: posY })
  }

  _handleTouchEnd = changedTouches => {
    const posY = changedTouches.screenY
    if (this.state.mouseDown) {
      this.setState({ mouseOut: true, mouseDown: false }, () => {
        if (this.state.posY > posY) {
          // this.props.onOpen("up")
        } else {
          // this.props.onOpen("down")
        }
      })
    }
  }

  render() {
    const {
      width,
      height,
      delay,
      ffcolor,
      bfcolor,
      wrapperimage,
      wrapperwidth,
    } = this.props
    const leftShift = Number(width.slice(0, -2)) / 4
    return (
      <RectFlipDiv
        className="rect-flip"
        width={width}
        height={height}
        leftShift={leftShift}
        draggable="true"
        onDragStart={e => this._handleDragStart(e)}
        onMouseOut={e => this._handleMouseOut(e)}
        onTouchStart={e => {
          // e.preventDefault()
          this._handleTouchStart(e.changedTouches[0])
        }}
        onTouchEnd={e => {
          // e.preventDefault()
          this._handleTouchEnd(e.changedTouches[0])
        }}
      >
        <RectFlipFront className="rect-flip-front" delay={delay}>
          <RectFlipFrontTop className="front-top" ffcolor={ffcolor} />
        </RectFlipFront>
        <RectFlipBack className="rect-flip-back" delay={delay}>
          <EnvelopeBack
            width={width}
            height={height}
            delay={delay}
            ffcolor={ffcolor}
            bfcolor={bfcolor}
            wrapperimage={wrapperimage}
            wrapperwidth={wrapperwidth}
          />
        </RectFlipBack>
      </RectFlipDiv>
    )
  }
}

const EnvelopeRightLeftDiv = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all ${props => props.delay}ms ease-in;
  z-index: 1;
`

class EnvelopeRectRightLeft extends Component {
  render() {
    const { width, height, delay, ffcolor, bfcolor } = this.props
    const topShift = Number(height.slice(0, -2)) / 4
    return (
      <EnvelopeRightLeftDiv
        className="envelope-right-left"
        width={width}
        height={height}
        delay={delay}
        draggable="true"
        onDragStart={e => {
          // e.preventDefault()
          this.props.onClose()
        }}
        onTouchStart={e => {
          // e.preventDefault()
          this.props.onClose()
        }}
      >
        <RectangleLeft
          width={width}
          height={height}
          delay={delay}
          ffcolor={ffcolor}
          bfcolor={bfcolor}
        />
        <RectangleRight
          width={width}
          height={height}
          delay={delay}
          ffcolor={ffcolor}
          bfcolor={bfcolor}
        />
      </EnvelopeRightLeftDiv>
    )
  }
}

const EnvelopeBackFaceDiv = styled.div`
  background: ${props => props.bfcolor};
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.width};
  height: ${props => props.height};
  position: absolute;
  top: 0;
  left: 0;
  @media (max-width: 700px) {
    left: -100px;
  }
`

class EnvelopeBackFaceRect extends Component {
  render() {
    const { width, height } = this.props
    const topShift = Number(height.slice(0, -2)) / 4
    return (
      <EnvelopeBackFaceDiv
        className="envelope-back-face"
        width={width}
        height={height}
        bfcolor={this.props.bfcolor}
        draggable="true"
        onDragStart={e => {
          // e.preventDefault()
          this.props.onClose()
        }}
      >
        {this.props.children}
      </EnvelopeBackFaceDiv>
    )
  }
}

const FlipContainerDiv = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: none;
`
const EnvelopeFrontWrapper = styled.div`
  margin: 0 auto;
  width: ${props => props.wrapperWidth};
  height: ${props => props.wrapperHeight};
  position: relative;
  top: 30%;
  left: 0;
`
class Fold extends Component {
  render() {
    const {
      envelopeWidth,
      envelopeHeight,
      wrapperWidth,
      wrapperHeight,
      animationDelay,
      wrapperFrontColor,
      wrapperBackColor,
      wrapperImage,
    } = this.props
    return (
      <FlipContainerDiv className="flip-container">
        <EnvelopeFrontWrapper
          wrapperWidth={wrapperWidth}
          wrapperHeight={wrapperHeight}
        >
          <EnvelopeFront
            showUpDownRect={this.props.showUpDownRect}
            width={envelopeWidth}
            height={envelopeHeight}
            delay={animationDelay}
            ffcolor={wrapperFrontColor}
            bfcolor={wrapperBackColor}
            wrapperimage={wrapperImage}
            wrapperwidth={wrapperWidth}
            // onOpen={direction => this.props.onOpen(direction)}
          />
          {this.props.showRightLeftRect && (
            <EnvelopeRectRightLeft
              width={wrapperWidth}
              height={wrapperHeight}
              delay={animationDelay}
              ffcolor={wrapperFrontColor}
              bfcolor={wrapperBackColor}
              onClose={e => this.props.onClose(e)}
            />
          )}
          {this.props.showBackFaceRect && (
            <EnvelopeBackFaceRect
              width={wrapperWidth}
              height={wrapperHeight}
              bfcolor={wrapperBackColor}
              onClose={e => this.props.onClose(e)}
            >
              <PurchasedGiftCard {...this.props} />
            </EnvelopeBackFaceRect>
          )}
        </EnvelopeFrontWrapper>
      </FlipContainerDiv>
    )
  }
}

const GiftCardImage = styled.img`
  opacity: ${props => (props.noexperience ? 1 : 0)};
  /* animation: ${bounce7} 4s ease-in-out 1s infinite running; */
  max-width: 50%;
  z-index: 2;
`
const PurchasedGiftCard = props => (
  <GiftCardImage
    className="gift-card-image"
    {...props}
    src={props.giftcard.product_image}
    alt={props.giftcard.brand_name}
  />
)

const ShowBackFaceRectDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`
const GiftCardOnlyHolder = styled.div`
  display: grid;
  align-items: start;
  justify-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
`
const AnimatedGiftCardWrapper = styled.div`
  position: relative;
  top: 12.5%;
  z-index: 2;
`

class Paper extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showUpDownRect: false,
      showRightLeftRect: false,
      showBackFaceRect: false,
      timeoutSeconds: 1000,
      wrapperOpenWidth: "600px",
      wrapperOpenHeigth: "400px",
      wrapperClosedWidth: "400px",
      wrapperClosedHeight: "200px",
      wrapperImage: this.props.giftwrapper || "hearts.png",
      wrapperBackFaceColor: "#fff",
    }
    this.paperTimeout = null
  }

  componentDidMount() {
    if (this.props.startExperience) {
      this.paperTimeout = setTimeout(() => {
        this._flipEnvelope("up")
      }, 500)
    }
  }

  componentDidUpdate() {
    if (this.props.startExperience) {
      this.paperTimeout = setTimeout(() => {
        this._flipEnvelope("up")
      }, 500)
    }
  }

  componentWillUnmount() {
    clearTimeout(this.paperTimeout)
  }

  _flipEnvelope = dir => {
    const front = document.querySelector(".rect-flip-front")
    const back = document.querySelector(".rect-flip-back")
    if (front && back) {
      if (dir === "up") {
        front.classList.add("rect-flip-front-hover-up")
        front.classList.remove("rect-flip-front")
        back.classList.add("rect-flip-back-hover-up")
        back.classList.remove("rect-flip-back")
      } else {
        front.classList.add("rect-flip-front-hover-down")
        front.classList.remove("rect-flip-front")
        back.classList.add("rect-flip-back-hover-down")
        back.classList.remove("rect-flip-back")
      }
      setTimeout(() => {
        this._unfoldBigTriangle()
      }, this.state.timeoutSeconds)
    }
  }

  _unFlipEnvelope = () => {
    const ufront = document.querySelector(".rect-flip-front-hover-up")
    const uback = document.querySelector(".rect-flip-back-hover-up")
    const dfront = document.querySelector(".rect-flip-front-hover-down")
    const dback = document.querySelector(".rect-flip-back-hover-down")
    if (ufront && uback) {
      ufront.classList.add("rect-flip-front")
      ufront.classList.remove("rect-flip-front-hover-up")
      uback.classList.add("rect-flip-back")
      uback.classList.remove("rect-flip-back-hover-up")
      setTimeout(() => {
        this._foldBigTriangle()
      }, this.state.timeoutSeconds)
    } else if (dfront && dback) {
      dfront.classList.add("rect-flip-front")
      dfront.classList.remove("rect-flip-front-hover-down")
      dback.classList.add("rect-flip-back")
      dback.classList.remove("rect-flip-back-hover-down")
      setTimeout(() => {
        this._foldBigTriangle()
      }, this.state.timeoutSeconds)
    }
  }

  _unfoldBigTriangle = () => {
    const front = document.querySelector(".right-triangle-front")
    const back = document.querySelector(".right-triangle-back")
    const lfront = document.querySelector(".left-triangle-front")
    const lback = document.querySelector(".left-triangle-back")
    const leftup = document.querySelector(".left-up")
    const leftdown = document.querySelector(".left-down")
    const rightup = document.querySelector(".right-up")
    const rightdown = document.querySelector(".right-down")
    const rtsvg = document.querySelector(".right")
    const ltsvg = document.querySelector(".left")
    if (front && back && lfront && lback) {
      front.classList.add("right-triangle-front-hover")
      front.classList.remove("right-triangle-front")
      back.classList.add("right-triangle-back-hover")
      back.classList.remove("right-triangle-back")
      lfront.classList.add("left-triangle-front-hover")
      lfront.classList.remove("left-triangle-front")
      lback.classList.add("left-triangle-back-hover")
      lback.classList.remove("left-triangle-back")
      setTimeout(() => {
        leftup.style.visibility = "visible"
        leftdown.style.visibility = "visible"
        rightup.style.visibility = "visible"
        rightdown.style.visibility = "visible"
      }, 600)
      setTimeout(() => {
        rtsvg.style.zIndex = 0
        ltsvg.style.zIndex = 0
        this._unfoldSmallTriangle()
      }, this.state.timeoutSeconds)
    }
  }

  _unfoldSmallTriangle = () => {
    const { wrapperOpenWidth, wrapperClosedWidth } = this.state
    const leftShift =
      (Number(wrapperOpenWidth.slice(0, -2)) -
        Number(wrapperClosedWidth.slice(0, -2))) /
      2
    const lupfront = document.querySelector(".left-up-triangle-front")
    // const lupback = document.querySelector('.left-up-triangle-back')
    const ldownfront = document.querySelector(".left-down-triangle-front")
    // const ldownback = document.querySelector('.left-down-triangle-back')
    const rupfront = document.querySelector(".right-up-triangle-front")
    // const rupback = document.querySelector('.right-up-triangle-back')
    const rdownfront = document.querySelector(".right-down-triangle-front")
    // const rdownback = document.querySelector('.right-down-triangle-back')
    const rectup = document.querySelector(".rect-up")
    const rectdown = document.querySelector(".rect-down")
    const rectmaskleft = document.querySelector(".rect-mask-left-inner")
    const rectmaskright = document.querySelector(".rect-mask-right-inner")
    // if (lupfront && lupback && ldownfront && ldownback && rupfront && rupback && rdownfront && rdownback) {
    if (lupfront && ldownfront && rupfront && rdownfront) {
      lupfront.classList.add("left-up-triangle-front-hover")
      lupfront.classList.remove("left-up-triangle-front")
      // lupback.classList.add('left-up-triangle-back-hover')
      // lupback.classList.remove('left-up-triangle-back')
      ldownfront.classList.add("left-down-triangle-front-hover")
      ldownfront.classList.remove("left-down-triangle-front")
      // ldownback.classList.add('left-down-triangle-back-hover')
      // ldownback.classList.remove('left-down-triangle-back')
      // right small triangles
      rupfront.classList.add("right-up-triangle-front-hover")
      rupfront.classList.remove("right-up-triangle-front")
      // rupback.classList.add('right-up-triangle-back-hover')
      // rupback.classList.remove('right-up-triangle-back')
      rdownfront.classList.add("right-down-triangle-front-hover")
      rdownfront.classList.remove("right-down-triangle-front")
      // rdownback.classList.add('right-down-triangle-back-hover')
      // rdownback.classList.remove('right-down-triangle-back')
      setTimeout(() => {
        rectup.style.width = wrapperOpenWidth
        rectup.style.left = `-${leftShift}px`
        rectdown.style.width = wrapperOpenWidth
        rectdown.style.left = `-${leftShift}px`
        rectmaskleft.style.width = "75%"
        rectmaskleft.style.left = `-${leftShift}px`
        rectmaskright.style.width = "75%"
      }, 700)
      setTimeout(() => {
        this.setState(
          {
            showUpDownRect: true,
          },
          () => {
            setTimeout(() => {
              this._unfoldRectUpDown()
            }, 1)
          }
        )
      }, this.state.timeoutSeconds)
    }
  }

  _unfoldRectUpDown = () => {
    const { wrapperOpenHeight, wrapperClosedHeight } = this.state
    const ufront = document.querySelector(".rect-up-front")
    const uback = document.querySelector(".rect-up-back")
    const dfront = document.querySelector(".rect-down-front")
    const dback = document.querySelector(".rect-down-back")
    const rectmaskleft = document.querySelector(".rect-mask-left-inner")
    const rectmaskright = document.querySelector(".rect-mask-right-inner")
    if (ufront && uback && dfront && dback) {
      ufront.classList.add("rect-up-front-hover")
      ufront.classList.remove("rect-up-front")
      uback.classList.add("rect-up-back-hover")
      uback.classList.remove("rect-up-back")
      // down rect
      dfront.classList.add("rect-down-front-hover")
      dfront.classList.remove("rect-down-front")
      dback.classList.add("rect-down-back-hover")
      dback.classList.remove("rect-down-back")
      setTimeout(() => {
        rectmaskleft.style.height = wrapperOpenHeight
        rectmaskleft.style.top = `-${wrapperClosedHeight}px`
        // rectmaskleft.style.zIndex = '900'
        rectmaskright.style.height = wrapperOpenHeight
        rectmaskright.style.top = `-${wrapperClosedHeight}px`
        // rectmaskright.style.zIndex = '900'
      }, 900)
      setTimeout(() => {
        this.setState(
          {
            showRightLeftRect: true,
          },
          () => {
            const rectrightleft = document.querySelector(".envelope-right-left")
            rectrightleft.style.opacity = 1
            setTimeout(() => {
              this._unfoldRectRightLeft()
            }, 100)
          }
        )
      }, this.state.timeoutSeconds)
    }
  }

  _unfoldRectRightLeft = () => {
    const rfront = document.querySelector(".rect-right-front")
    const rback = document.querySelector(".rect-right-back")
    const lfront = document.querySelector(".rect-left-front")
    const lback = document.querySelector(".rect-left-back")
    const rectflip = document.querySelector(".rect-flip")
    if (rfront && rback && lfront && lback) {
      rfront.classList.add("rect-right-front-hover")
      rfront.classList.remove("rect-right-front")
      rback.classList.add("rect-right-back-hover")
      rback.classList.remove("rect-right-back")
      // left rect
      lfront.classList.add("rect-left-front-hover")
      lfront.classList.remove("rect-left-front")
      lback.classList.add("rect-left-back-hover")
      lback.classList.remove("rect-left-back")
      setTimeout(() => {
        this.setState(
          {
            showBackFaceRect: true,
          },
          () => {
            setTimeout(() => {
              rectflip.style.zIndex = 0
            }, 1)
            setTimeout(() => {
              const giftCardImage = document.querySelector(".gift-card-image")
              // giftCardImage.style.zIndex = 2
              if (giftCardImage) {
                giftCardImage.style.opacity = 1
              }
            }, 300)
          }
        )
      }, 1)
    }
  }

  _foldBigTriangle = () => {
    const rfront = document.querySelector(".right-triangle-front-hover")
    const rback = document.querySelector(".right-triangle-back-hover")
    const lfront = document.querySelector(".left-triangle-front-hover")
    const lback = document.querySelector(".left-triangle-back-hover")
    const leftup = document.querySelector(".left-up")
    const leftdown = document.querySelector(".left-down")
    const rightup = document.querySelector(".right-up")
    const rightdown = document.querySelector(".right-down")
    if (rfront && rback && lfront && lback) {
      leftup.style.visibility = "hidden"
      leftdown.style.visibility = "hidden"
      rightup.style.visibility = "hidden"
      rightdown.style.visibility = "hidden"
      setTimeout(() => {
        rfront.classList.add("right-triangle-front")
        rfront.classList.remove("right-triangle-front-hover")
        rback.classList.add("right-triangle-back")
        rback.classList.remove("right-triangle-back-hover")
        lfront.classList.add("left-triangle-front")
        lfront.classList.remove("left-triangle-front-hover")
        lback.classList.add("left-triangle-back")
        lback.classList.remove("left-triangle-back-hover")
      }, 300)
      setTimeout(() => {
        this._unFlipEnvelope()
      }, this.state.timeoutSeconds)
    }
  }

  _foldSmallTriangle = () => {
    const { wrapperClosedWidth } = this.state
    const lupfront = document.querySelector(".left-up-triangle-front-hover")
    // const lupback = document.querySelector('.left-up-triangle-back-hover')
    const ldownfront = document.querySelector(".left-down-triangle-front-hover")
    // const ldownback = document.querySelector('.left-down-triangle-back-hover')
    const rupfront = document.querySelector(".right-up-triangle-front-hover")
    // const rupback = document.querySelector('.right-up-triangle-back-hover')
    const rdownfront = document.querySelector(
      ".right-down-triangle-front-hover"
    )
    // const rdownback = document.querySelector('.right-down-triangle-back-hover')
    const rectup = document.querySelector(".rect-up")
    const rectdown = document.querySelector(".rect-down")
    const rectmaskleft = document.querySelector(".rect-mask-left-inner")
    const rectmaskright = document.querySelector(".rect-mask-right-inner")
    const rtsvg = document.querySelector(".right")
    const ltsvg = document.querySelector(".left")
    if (lupfront && ldownfront && rupfront && rdownfront) {
      // if (lupfront && ldownfront && rupfront && rupback && rdownfront && rdownback) {
      // if (lupfront && lupback && ldownfront && ldownback && rupfront && rupback && rdownfront && rdownback) {
      setTimeout(() => {
        rectup.style.width = wrapperClosedWidth
        rectup.style.left = "0"
        rectdown.style.width = wrapperClosedWidth
        rectdown.style.left = "0"
        rectmaskleft.style.width = "50%"
        rectmaskleft.style.left = "0"
        rectmaskright.style.width = "50%"
      }, 500)
      lupfront.classList.add("left-up-triangle-front")
      lupfront.classList.remove("left-up-triangle-front-hover")
      // lupback.classList.add('left-up-triangle-back')
      // lupback.classList.remove('left-up-triangle-back-hover')
      ldownfront.classList.add("left-down-triangle-front")
      ldownfront.classList.remove("left-down-triangle-front-hover")
      // ldownback.classList.add('left-down-triangle-back')
      // ldownback.classList.remove('left-down-triangle-back-hover')
      // right small triangles
      rupfront.classList.add("right-up-triangle-front")
      rupfront.classList.remove("right-up-triangle-front-hover")
      // rupback.classList.add('right-up-triangle-back')
      // rupback.classList.remove('right-up-triangle-back-hover')
      rdownfront.classList.add("right-down-triangle-front")
      rdownfront.classList.remove("right-down-triangle-front-hover")
      // rdownback.classList.add('right-down-triangle-back')
      // rdownback.classList.remove('right-down-triangle-back-hover')
      setTimeout(() => {
        rtsvg.style.zIndex = 900
        ltsvg.style.zIndex = 900
        setTimeout(() => {
          this._foldBigTriangle()
        }, 1)
      }, this.state.timeoutSeconds)
    }
  }

  _foldRectUpDown = () => {
    const ufront = document.querySelector(".rect-up-front-hover")
    const uback = document.querySelector(".rect-up-back-hover")
    const dfront = document.querySelector(".rect-down-front-hover")
    const dback = document.querySelector(".rect-down-back-hover")
    if (ufront && uback && dfront && dback) {
      ufront.classList.add("rect-up-front")
      ufront.classList.remove("rect-up-front-hover")
      uback.classList.add("rect-up-back")
      uback.classList.remove("rect-up-back-hover")
      // down rect
      dfront.classList.add("rect-down-front")
      dfront.classList.remove("rect-down-front-hover")
      dback.classList.add("rect-down-back")
      dback.classList.remove("rect-down-back-hover")
      setTimeout(() => {
        this._foldSmallTriangle()
      }, this.state.timeoutSeconds)
    }
  }

  _foldRectRightLeft = () => {
    const { wrapperClosedHeight } = this.state
    const topShift = Number(wrapperClosedHeight.slice(0, -2)) / 2
    const rfront = document.querySelector(".rect-right-front-hover")
    const rback = document.querySelector(".rect-right-back-hover")
    const lfront = document.querySelector(".rect-left-front-hover")
    const lback = document.querySelector(".rect-left-back-hover")
    const rectmaskleft = document.querySelector(".rect-mask-left-inner")
    const rectmaskright = document.querySelector(".rect-mask-right-inner")
    if (rfront && rback && lfront && lback) {
      rfront.classList.add("rect-right-front")
      rfront.classList.remove("rect-right-front-hover")
      rback.classList.add("rect-right-back")
      rback.classList.remove("rect-right-back-hover")
      // left rect
      lfront.classList.add("rect-left-front")
      lfront.classList.remove("rect-left-front-hover")
      lback.classList.add("rect-left-back")
      lback.classList.remove("rect-left-back-hover")
      setTimeout(() => {
        const giftCardImage = document.querySelector(".gift-card-image")
        if (giftCardImage) {
          giftCardImage.style.zIndex = 0
        }
      }, 500)
      setTimeout(() => {
        this.setState(
          {
            showBackFaceRect: false,
            showRightLeftRect: false,
          },
          () => {
            rectmaskleft.style.height = wrapperClosedHeight
            rectmaskleft.style.top = `-${topShift}px`
            // rectmaskleft.style.zIndex = '899'
            rectmaskright.style.height = wrapperClosedHeight
            rectmaskright.style.top = `-${topShift}px`
            // rectmaskright.style.zIndex = '899'
            setTimeout(() => {
              this._foldRectUpDown()
            }, 1)
          }
        )
      }, this.state.timeoutSeconds)
    }
  }

  render() {
    const {
      wrapperOpenHeigth,
      wrapperOpenWidth,
      wrapperClosedHeight,
      wrapperClosedWidth,
      wrapperImage,
      wrapperBackFaceColor,
    } = this.state
    return (
      <div className="page">
        {this.props.giftwrapper ? (
          <Fragment>
            <Fold
              envelopeWidth={wrapperClosedWidth}
              envelopeHeight={wrapperClosedHeight}
              wrapperWidth={wrapperOpenWidth}
              wrapperHeight={wrapperOpenHeigth}
              animationDelay={this.state.timeoutSeconds}
              wrapperFrontColor={`url(${wrapperImage})`}
              wrapperImage={wrapperImage}
              wrapperBackColor={wrapperBackFaceColor}
              showUpDownRect={this.state.showUpDownRect}
              showRightLeftRect={this.state.showRightLeftRect}
              showBackFaceRect={this.state.showBackFaceRect}
              giftcard={this.props.giftcard}
              // onOpen={dir => {
              //   this._flipEnvelope(dir)
              // }}
              onClose={() => null}
            />
            {this.state.showBackFaceRect && (
              <CanvasConfetti cancelAnimation={!this.state.showBackFaceRect} />
            )}
            {this.state.showBackFaceRect && (
              <ShowBackFaceRectDiv draggable="true"></ShowBackFaceRectDiv>
            )}
          </Fragment>
        ) : (
          <Fragment>
            <GiftCardOnlyHolder>
              <CanvasConfetti />
              <AnimatedGiftCardWrapper className="animated-giftcard__wrapper">
                <PurchasedGiftCard {...this.props} noexperience={true} />
              </AnimatedGiftCardWrapper>
            </GiftCardOnlyHolder>
          </Fragment>
        )}
      </div>
    )
  }
}

export default Paper

// const FoldTwo = props => {
//   return (
//     <div
//       className="fold-two"
//       css={css`
//         width: 100%;
//         height: 400px;
//         background: transparent;
//       `}
//     ></div>
//   )
// }

// export const PaperTwo = props => {
//   return <FoldTwo {...props} />
// }
