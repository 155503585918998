import React, { Component, useState } from "react"
import styled from "styled-components"

class ConfettiParticle {
  constructor(context, width, height) {
    this.context = context
    this.width = width
    this.height = height
    this.color = ""
    this.lightness = 50
    this.diameter = 0
    this.tilt = 0
    this.tiltAngleIncrement = 0
    this.tiltAngle = 0
    this.particleSpeed = 1
    this.waveAngle = 0
    this.x = 0
    this.y = 0
    this.reset()
  }

  reset() {
    this.lightness = 50
    this.color = Math.floor(Math.random() * 360)
    this.x = Math.random() * this.width
    this.y = Math.random() * this.height - this.height
    this.diameter = Math.random() * 6 + 4
    this.tilt = 0
    this.tiltAngleIncrement = Math.random() * 0.1 + 0.04
    this.tiltAngle = 0
  }

  darken() {
    if (this.y < 100 || this.lightness <= 0) return
    this.lightness -= 250 / this.height
  }

  update() {
    this.waveAngle += this.tiltAngleIncrement
    this.tiltAngle += this.tiltAngleIncrement
    this.tilt = Math.sin(this.tiltAngle) * 12
    this.x += Math.sin(this.waveAngle)
    this.y +=
      (Math.cos(this.waveAngle) + this.diameter + this.particleSpeed) * 0.4
    if (this.complete()) this.reset()
    // this.darken()
  }

  complete() {
    return this.y > this.height + 20
  }

  draw() {
    let x = this.x + this.tilt
    this.context.beginPath()
    this.context.lineWidth = this.diameter
    this.context.strokeStyle =
      "hsl(" + this.color + ", 50%, " + this.lightness + "%)"
    this.context.moveTo(x + this.diameter / 2, this.y)
    this.context.lineTo(x, this.y + this.tilt + this.diameter / 2)
    this.context.stroke()
    this.context.closePath()
  }
}

class Canvas extends Component {
  constructor(props) {
    super(props)
    this.state = {
      animationReq: null,
    }
    this._isMounted = false
  }

  componentDidMount() {
    this._isMounted = true
    this._createParticles()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  _createParticles = () => {
    if (this._isMounted) {
      const context = this.refs.canvas.getContext("2d")
      const { width, height } = this.props
      let particles = []
      Array(500)
        .fill(0)
        .forEach(() => {
          particles.push(new ConfettiParticle(context, width, height))
        })
      this.setState(
        {
          particles: particles.slice(),
        },
        () => this._animationFunc()
      )
    }
  }

  _animationFunc = () => {
    if (this._isMounted) {
      let animationReq = requestAnimationFrame(this._animationFunc)
      this.setState(
        {
          animationReq: animationReq,
        },
        () => {
          if (this.props.cancelAnimation) {
            cancelAnimationFrame(this.state.animationReq)
          }
          if (this.state.animationReq) {
            const { width, height } = this.props
            const context = this.refs.canvas.getContext("2d")
            context.clearRect(0, 0, width, height)
            for (let p of this.state.particles) {
              p.width = width
              p.height = height
              p.update()
              p.draw()
            }
          }
        }
      )
    }
  }

  render() {
    const { width, height } = this.props
    return <CanvasStyle ref="canvas" width={width} height={height} />
  }
}

const CanvasStyle = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
`

export default props => {
  const [width, setWidth] = useState(window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight)

  return (
    <Canvas
      id="confetti-canvas"
      width={width}
      height={height}
      setWidth={setWidth}
      setHeight={setHeight}
      cancelAnimation={props.cancelAnimation}
    />
  )
}
