import React, { useEffect } from "react"
import styled from "styled-components"

const GiftExperienceAnimation = styled.div`
  margin: 0 auto;
  width: 100%;
`
const VideoComponent = styled.video`
  width: 100%;
`

export default function AnimationView(props) {
  useEffect(() => {
    const video = document.querySelector("video")
    if (
      props.giftDetails &&
      props.giftDetails.experience.status &&
      props.giftDetails.experience.animation &&
      props.giftDetails.experience.animation.animation_file_location
    ) {
      video.addEventListener("ended", () => {
        props.setShowAnimation(false)
        if (props.giftDetails.experience.greeting_card) {
          props.setShowGreeting(true)
          props.setOpenGreeting(true)
        } else {
          props.setShowGift(true)
          props.setGiftOpen(true)
        }
      })
      return () => {
        video.removeEventListener("ended", () => null)
      }
    }
  }, [props.giftDetails])

  useEffect(() => {
    if (
      props.giftDetails &&
      props.giftDetails.experience.status &&
      props.giftDetails.experience.animation &&
      props.giftDetails.experience.animation.animation_file_location &&
      props.showAnimation
    ) {
      const video = document.querySelector("video")
      if (video) {
        video.addEventListener("canplaythrough", e => {
          // video.play()
          props.setShowLoading(false)
        })

        video.load()
        return () => {
          video.removeEventListener("canplaythrough", () => null)
        }
      }
    }
  }, [props.giftDetails])

  useEffect(() => {
    if (props.startExperience && !props.errorFetchingAnimation) {
      const video = document.querySelector("video")
      video.play()
    }
  }, [props.startExperience])

  useEffect(() => {
    if (props.errorFetchingAnimation) {
      if (
        props.giftDetails.experience.status &&
        props.giftDetails.experience.greeting_card
      ) {
        props.setShowGreeting(true)
        props.setOpenGreeting(true)
      } else {
        props.setShowGift(true)
        props.setGiftOpen(true)
      }
    }
  }, [props])

  return (
    <GiftExperienceAnimation>
      {props.showLoading && <h1>Loading....</h1>}
      {props.giftDetails &&
        props.giftDetails.experience.status &&
        props.giftDetails.experience.animation &&
        props.giftDetails.experience.animation.animation_file_location && (
          <VideoComponent playsInline muted>
            <source
              src={`${process.env.GATSBY_EGIFTIT_B2B}${
                props.giftDetails &&
                props.giftDetails.experience.status &&
                props.giftDetails.experience.animation &&
                props.giftDetails.experience.animation.animation_file_location
              }`}
              type="video/mp4"
            />
            Sorry, your browser doesn't support embedded videos.
          </VideoComponent>
        )}
    </GiftExperienceAnimation>
  )
}
